a {
  text-decoration: none;
  color: #FF601C;
  cursor: none;
}

@font-face {
  font-family: tusker1;
  src: url(fonts/TuskerGrotesk-4600Semibold.ttf);
}

@font-face {
  font-family: tusker2;
  src: url(fonts/TuskerGrotesk-8800Super.ttf);
}

@font-face {
  font-family: sfr;
  src: url(fonts/SF-Pro-Display-Regular.otf);
}

@font-face {
  font-family: sfb;
  src: url(fonts/SF-Pro-Display-Black.otf);
}


body {
  color: #FF601C;
  background-color: #FDF7EC;
  font-family: "Dela Gothic One", sans-serif;
  margin: 0;
  padding: 0;
  cursor: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1 {
  font-family: "Dela Gothic One", sans-serif;
  text-align: center;
  font-size: 50pt;
  color: #323334;
  margin: 20pt 0;
  padding: 0;
}

h2 {
  font-family: "Dela Gothic One", sans-serif;
  text-align: center;
  font-size: 30pt;
  color: #323334;
  margin: 20pt 0;
  padding: 0;
}

p {
  font-family: sfr;
  margin: 30pt 0;
  padding: 0;
  color: #323334;
  text-align: center;
  font-size: 16pt;
}

.container {
  width: auto;
  padding: 0px 40px 0px 40px;
}

.section3 {
  display: block;
  z-index: 2;
  background-color: #FDF7EC;
}

.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  font-size: 12pt;
  gap: 36px;
  color: #FF601C;
}


.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  align-items: baseline;
}

.menu-item {
  white-space: nowrap;
}

.menu-item:hover {
  text-decoration: underline;
}

.menu-item-outlined {
  border: 1px solid #FF601C;
  border-radius: 100px;
  padding: 10px 30px;
  white-space: nowrap;
}

.selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 36px 0;
  z-index: 2;
}

.select {
  transition: 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.select::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FF601C; /* Silme efekti rengi */
  transform: translateY(100%);
  transition: transform 0.3s ease;
  z-index: 1;
}
.select:hover::before {
  transform: translateY(0);
}

.select:hover .selection {
  transition: 0.3s ease;
  color: #fff; /* Hover durumunda metin rengi */
}

.select:hover .selection-right {
  transition: 0.3s ease;
  color: #fff; /* Hover durumunda metin rengi */
}
.select:hover .selection-left {
  transition: 0.3s ease;
  color: #fff; /* Hover durumunda metin rengi */
}

.select:hover .see-more-text {
  transition: 0.3s ease;
  color: #fff; /* Hover durumunda metin rengi */
}

.select:hover svg {
  transition: 0.3s ease;
  fill: #fff;
}

.select:hover .selection-image  {
  transition: 0.3s ease;
  box-shadow:0px 0px 0px 1px #fff;
}


.selection-right {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
  z-index: 2;
  position: relative;
}

.selection-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  color: #202020;
  gap: 20px;
  z-index: 2;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #DEDEDE;
}

.selection-order {
  font-family: tusker1;
  font-size: 14pt;
}

.see-more-text {
  font-family: tusker1;
  font-size: 14pt;
  color: #3E3D3D;
}

.selection-image {
  width: 136px;
  height: 68px;
  border-radius: 200px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 20px;
}

.selection-title {
  font-size: 66pt;
  letter-spacing: -2pt;
}

.title1 {
  font-size: 100pt;
  font-family: tusker2;
  max-width: 100%;
  min-width: auto;
  display: inline-block;
  width: 100%;
}

.title2 {
  font-size: 60pt;
  font-family: tusker2;
  max-width: 100%;
  min-width: auto;
  display: inline-block;
  width: 100%;
}

.motion-video {
  width: 200px;
  height: 600px;
}

.section4 {
  background-color: #fff;
}

.section7 {
  background-color: #fff;
  padding: 50px 0;
}

.scroll-item {
  width: 400px;
  height: 600px;
  overflow: hidden;
  display: flex;
  align-content: center;
  border-radius: 20px;
  position: relative;
  margin: 20px;
  flex: 0 0 auto;
  will-change: transform;
}

.scroll-item > img {
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: fill;
  opacity: 1;
}

.scroll-item:hover > img {
  opacity: 0;
  transition: 0.1s;
  transition-delay: 0.3s;
}

.scroll-item > video {
  width: 100%;
  height: auto;
  object-fit: fill;
}

.scroll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  will-change: scroll-position;
  transform: translateZ(0);
}

.scroll::-webkit-scrollbar {
  display: none;
}

.up {
  margin-top: 100px;
}

.video-scroll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  will-change: scroll-position;
  transform: translateZ(0);
}

.video-scroll::-webkit-scrollbar {
  display: none;
}


.col-1{
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col-2{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col-3{
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.line-word {
  width: 200%;
  height: 150px;
  background-color: #FF601C;
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  rotate: 352deg;
  position: relative;
  left: -50%;
  top: 150px;
  align-items: center;
  font-family: tusker2;
  text-wrap: nowrap;
}



.loop-text {
  font-size: 50pt;
  animation: loopText 5s infinite linear;      
}

@keyframes loopText {

from {
    transform: translateX(0);
 }

to {
    transform: translateX(-100%);
 }

}

.line-categories {
  width: 200%;
  height: 150px;
  color: #FF601C;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  rotate: 8deg;
  position: relative;
  left: -50%;
  top: 250px;
  align-items: center;
  font-family: "Dela Gothic One", sans-serif;
  text-wrap: nowrap;
  z-index: -1;
}

.category-item {
  animation: reverse loopText 12s infinite linear;
  white-space: nowrap;
  display: flex;
  flex-direction: row;

}

.category-item > p {
  font-size: 30pt;
  border: 1px solid #FF601C;
  border-radius: 100px;
  padding: 10px 30px;
  margin: 5px;
  font-family: "Dela Gothic One", sans-serif;
  color: #FF601C;
}

.hero-banner {
  width: 100%;
  height: 800px;
  overflow: hidden;
}

@keyframes category-item {

from {
    transform: translateX(0);
 }

to {
    transform: translateX(-10);
 }

}

.clipper {
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.about_text {
  width: auto;
  position: relative;
  left: 0;
  font-family: sfr;
  font-size: 30pt;
  color: #585858;
  padding: 40px 0;
}


.about_text > span {
  font-family: sfb;
  color: #FF601C;
}

.orange-line {
  width: 100%;
  background-color: #FF601C;
  height: 2px;
}

.footer-items {
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-left {
  display: flex;
  flex-direction: row;
  font-size: 20pt;
  align-items: baseline;
}

.footer-right {
  display: flex;
  flex-direction: row;
  font-size: 14pt;
}

.footer-right:hover {
  text-decoration: underline;
}

.footer-left-child:hover {
  text-decoration: underline;
}

.vertical-seperator {
  width: 4px;
  height: 16px;
  background-color: #FF601C;
  margin: 0 30px;
  border-radius: 20px;
}

.contact-me-icon > img {
  height: 22px;
  margin-right: 20px;
}

.logo > img {
  height: 50px;
  top: 20px;
  position: relative;
}

.about-all {
  display: flex;
  flex-direction: row;
}

.video-scroll {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}


.scroll-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  overflow: hidden;
}

.scroll-object {
  width: auto;
  height: auto;
}

.scroll-child {
  overflow: hidden;
  border-radius: 20px;
  margin: 10px;
}

.scroll-child > img {
  height: 100%;
  width: 100%;
  object-fit:cover;
}

.scroll-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: max-content;
}

.scrolling .scroll-content {
  animation: scroll var(--animation-duration) linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-1 * var(--scroll-width) / 2));
  }
}

.lay1 {
  width: 400px;
  height: 600px;
}

.lay2 {
  width: 400px;
  height: 395px;
}

.lay3 {
  width: 400px;
  height: 195px;
}

.lay4 {
  width: 400px;
  height: 295px;
}

#dragtoscroll {
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.print-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 20px 0;
  gap: 20px;
}

.print-image {
  overflow: hidden;
  border-radius: 20px;
  height: 12vw;
  width: 100%;
}

.print-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.print-text {
  font-family: 'tusker2';
  font-size: 4vw;
  align-self: center;
  padding: 0 40px;
}

.flex1 {
  flex: 1;
}

.flex15 {
  flex: 1.5;
}

.flex2 {
  flex: 2;
}

.column-gap {
  height: 100px;
}

.project-container {
    width: 70%;
    left: 15%;
    position: relative;
}

.project-image-full {
  width: 100%;
  height: auto;
}

.flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.flex > img {
  width: 0;
  height: auto;
  flex: 1 1;
}